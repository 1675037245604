import React, {useState, useEffect} from "react";
import {  Grid, Typography, List, ListItem,Container,Paper} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "react-router-dom";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import * as api from "../../../api/auth";


const WithdrawalHistory = () => {
    const history = useHistory();
    const [record, setRecord] = useState();
    const URL =  api.url;
    const [level, setLevel] = React.useState('Success');
    const handleLevel = (level) => async (event) => {
        setLevel(level);
    };



  useEffect(() => {
    
    const loggedInUser =  localStorage.getItem("user");
      if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const AuthStr = 'Bearer '.concat(foundUser.token); 
      axios.get(`${URL}/getWithdrawal/${foundUser.result.id}`, { headers: { Authorization: AuthStr } })
      .then(response => {
          setRecord(response.data);
    })
      .catch((error) => {
        console.log(error);
        history.push('/login');
    });
      

         
    }else{
      window.location.replace("/login");

    }
    
  }, []); 
   
    
    return (
        <div>
            <Grid container direction="row" justify="" alignItems="center" style={{paddingLeft: '20px',paddingTop: '15px',paddingBottom: '15px',paddingRight: '20px', backgroundColor: 'white'}}>
                <Grid item xs={4}>  
                <Link to="../wallet">
                <ArrowBackIosIcon style={{fontSize:'20px'}} />
                </Link>
                
                                
                 </Grid>
                 <Grid item xs={4}>                    
                <Typography align="center" st>Withdrawal Records</Typography>
                 </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: "10px" }}>
          <Grid item xs={4} onClick={handleLevel('Success')}>
            <Container
              style={{
                width: "100%",
                height: "30px",
                border: "1px solid",
                borderColor: "#05c0b8",
                backgroundColor: level === 'Success' ? "#05c0b8" : "white",
              }}
            >
              <Typography
                align="center"
                style={{
                  color: level === 'Success'? "white" : "black",
                  paddingTop: "3px",
                }}
              >
                Success
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={4} onClick={handleLevel('Placed')}>
            <Container
              style={{
                width: "100%",
                height: "30px",
                border: "1px solid",
                borderColor: "#05c0b8",
                backgroundColor: level === 'Placed' ? "#05c0b8" : "white",
              }}
            >
              <Typography
                align="center"
                style={{
                  color: level === 'Placed' ? "white" : "black",
                  paddingTop: "3px",
                }}
              >
                 Placed
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={4} onClick={handleLevel('Other')}>
            <Container
              style={{
                width: "100%",
                height: "30px",
                border: "1px solid",
                borderColor: "#05c0b8",
                backgroundColor: level === 'Other' ? "#05c0b8" : "white",
              }}
            >
              <Typography
                align="center"
                style={{
                  color: level === 'Other' ? "white" : "black",
                  paddingTop: "3px",
                }}
              >
                Fail
              </Typography>
            </Container>
          </Grid>
        </Grid>
            <Grid container direction="row" style={{padding: '10px'}}>
                <Grid item xs={5}>
                    <Typography>Date</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>Amount</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>Status</Typography>
                </Grid>
            </Grid>
            {/* <Paper
              style={{
                margin: '10px',
              
                height: "30px",
                border: "1px solid",
                borderColor: "#05c0b8",
                backgroundColor: "white",
              }}
            >
              <Grid container direction="row" style={{padding: '10px'}}>
                <Grid item xs={6}>
                    <Typography>Date</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>Amount</Typography>
                </Grid>
                
            </Grid>
            </Paper> */}
            {record && record.map(record => (
                record.status === level ? <div>
                    <List component="nav" aria-label="main mailbox folders"  >
            <ListItem >
                <Grid
                container
                alignItems="center"
                justify="space-between"
                direction="row"
                >
                <Grid item xs={5}>
                    <Typography style={{fontSize:'10px'}}>{new Date(record.date).toLocaleString()}</Typography>
                </Grid>
                <Grid item xs={3}>
                <Typography style={{fontWeight: "bold",}}>₹{(record.amount)}</Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography style={{color: record.status === 'Success' ? 'green': 'black'}}>{record.status}</Typography>
                </Grid>
                </Grid>
                
                </ListItem>
            </List>

                </div>
                : level === 'Other' ? (record.status !== 'Success' && record.status !== 'Placed') ? <div>
                <List component="nav" aria-label="main mailbox folders"  >
        <ListItem >
            <Grid
            container
            alignItems="center"
            justify="space-between"
            direction="row"
            >
            <Grid item xs={5}>
                <Typography style={{fontSize:'10px'}}>{new Date(record.date).toLocaleString()}</Typography>
            </Grid>
            <Grid item xs={3}>
            <Typography style={{fontWeight: "bold",}}>₹{(record.amount)}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography style={{color: record.status === 'Success' ? 'green': 'black'}}>{record.status}</Typography>
            </Grid>
            </Grid>
            
            </ListItem>
        </List>

            </div>:<div></div>:<div></div>
            
            ))}
            
        </div>
    )
}

export default WithdrawalHistory;
