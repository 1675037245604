import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  CircularProgress,
  Chip
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import * as api from "../../api/auth";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

const PromotionRecordNew = () => {
  const params = useParams();
  const history = useHistory();
  const URL = api.url;
 
  const [levelDataNew, setLevelDataNew] = useState({});
  const [liveBalance, setLiveBalance] = useState({});
  const [loader, setLoader] = React.useState(false);

  const fetchLiveBalance = (e , phone) => {
    e.preventDefault();
    const loggedInUser = localStorage.getItem("user");
    const foundUser = JSON.parse(loggedInUser);
    const AuthStr = "Bearer ".concat(foundUser.token);
    axios
        .get(`${URL}/getPromotionLiveBalance/${foundUser.result.id}/${phone}`, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          setLiveBalance({...liveBalance, [response.data.phone]: response.data.amount});
        })
        .catch((error) => {
          console.log(error);
          // history.push("/login");
        });
   
  }

  useEffect(() => {
    setLoader(true)
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const AuthStr = "Bearer ".concat(foundUser.token);
      
        axios
        .get(`${URL}/getPromotionNew/${foundUser.result.id}`, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          setLevelDataNew(response.data);
          setLoader(false)
        })
        .catch((error) => {
          console.log(error);
          history.push("/login");
        });  
    } else {
      history.push("/login");
    }
  }, []);

  // const getRecharge = (phoneNumber) => {

  //   var memberRequest = new XMLHttpRequest();
  //   memberRequest.onreadystatechange = function () {
  //     if (memberRequest.readyState === 4 && memberRequest.status === 200) {
  //       const response = JSON.parse(memberRequest.response);
  //       setMemberRecharge(response);

  //     }
  //   };
  //   memberRequest.open("GET", `https://secured.servider.net/playerJankaari/${phoneNumber}`, true);
  //   memberRequest.send();
  //   setRule(true);

  // }
  const [user, setUser] = useState([{ bonus: "Loading" }]);
  const [level, setLevel] = useState(0);
  const handleLevel = (level) => async (event) => {
    setLevel(level);
  };
  return (
    <div>
       <Dialog
          open={loader}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <Container  align="center" style={{backgroundColor: 'black', opacity: '0.6',height: '100px',paddingTop: "10px"}}>
          <CircularProgress style={{color: 'white',}} />
           <Typography style={{paddingTop: '10px', color: "white" }}>Please Wait!</Typography>
          </Container>
          
          
        </Dialog>
      <Grid
        container
        direction="row"
        justify=""
        alignItems="center"
        style={{
          paddingLeft: "20px",
          paddingTop: "15px",
          paddingBottom: "15px",
          paddingRight: "20px",
          backgroundColor: "white",
        }}
      >
        <Grid item xs={4}>
          
            <ArrowBackIosIcon onClick={history.goBack} style={{ fontSize: "20px" }} />
         
        </Grid>
        <Grid>
          <Typography align="center" >
            Today Promotion Record
          </Typography>
        </Grid>
      </Grid>
     
        <Typography align="center" style={{fontSize: '12px'}}>
          Today Promotion Record - Get details of your today invites.
        </Typography>
     
      <Grid container direction="row" style={{ padding: "10px" }}>
        <Grid item xs={4} onClick={handleLevel(0)}>
          <Container
            style={{
              width: "100%",
              height: "30px",
              border: "1px solid",
              borderColor: "#05c0b8",
              backgroundColor: level === 0 ? "#05c0b8" : "white",
            }}
          >
            <Typography
              align="center"
              style={{
                color: level === 0 ? "white" : "black",
                paddingTop: "3px",
              }}
            >
              Level 1
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={4} onClick={handleLevel(1)}>
          <Container
            style={{
              width: "100%",
              height: "30px",
              border: "1px solid",
              borderColor: "#05c0b8",
              backgroundColor: level === 1 ? "#05c0b8" : "white",
            }}
          >
            <Typography
              align="center"
              style={{
                color: level === 1 ? "white" : "black",
                paddingTop: "3px",
              }}
            >
              Level 2
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={4} onClick={handleLevel(2)}>
          <Container
            style={{
              width: "100%",
              height: "30px",
              border: "1px solid",
              borderColor: "#05c0b8",
              backgroundColor: level === 2 ? "#05c0b8" : "white",
            }}
          >
            <Typography
              align="center"
              style={{
                color: level === 2 ? "white" : "black",
                paddingTop: "3px",
              }}
            >
              Level 3
            </Typography>
          </Container>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ color: "#dbdbdb" }}>
                Phone
              </TableCell>
              
             
              <TableCell align="center" style={{ color: "#dbdbdb" }}>
                Today Recharge
              </TableCell>
              <TableCell align="center" style={{ color: "#dbdbdb" }}>
                Today WithDrawal
              </TableCell>
              <TableCell align="center" style={{ color: "#dbdbdb" }}>
                Today Bid
              </TableCell>
              <TableCell align="center" style={{ color: "#dbdbdb" }}>
                Live Balance
              </TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {level === 0 ? levelDataNew && levelDataNew.level0 &&
              Object.keys(levelDataNew.level0).map((row) => (
                
                <TableRow key={row.name}>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    style={{ paddingTop: "8px" }}
                  >
                                        {row}

                  </TableCell>
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  ₹{levelDataNew.level0[row]['todayRecharge']??0}
                  </TableCell>
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  ₹{levelDataNew.level0[row]['todayWithdrawal']??0}
                  </TableCell>
                  
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  ₹{levelDataNew.level0[row] && levelDataNew.level0[row]['bidToday'] && levelDataNew.level0[row]['bidToday'].toFixed(2)}
                  </TableCell>
            
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  {liveBalance[row] ? `₹${liveBalance[row].toFixed(2)}` : <Chip onClick={e => fetchLiveBalance(e,row)} label="Fetch Now"
                style={{ backgroundColor: "#00b8a9", color: "white" }}
              ></Chip>}
                  </TableCell>
                  
                </TableRow>
              )) : level === 1 ? levelDataNew && levelDataNew.level1 &&
              Object.keys(levelDataNew.level1).map((row) => (
                
                <TableRow key={row.name}>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    style={{ paddingTop: "8px" }}
                  >
                    {row}
                  </TableCell>
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  ₹{levelDataNew.level1[row]['todayRecharge']??0}
                  </TableCell>
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  ₹{levelDataNew.level1[row]['todayWithdrawal']??0}
                  </TableCell>
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  ₹{levelDataNew.level1[row] && levelDataNew.level1[row]['bidToday'] && levelDataNew.level1[row]['bidToday'].toFixed(2)}
                  </TableCell>
                  
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  {liveBalance[row] ? `₹${liveBalance[row].toFixed(2)}` : <Chip onClick={e => fetchLiveBalance(e,row)} label="Fetch Now"
                style={{ backgroundColor: "#00b8a9", color: "white" }}
              ></Chip>}
                  </TableCell>
                  
                </TableRow>
              )):
              levelDataNew && levelDataNew.level2 &&
              Object.keys(levelDataNew.level2).map((row) => (
                
                <TableRow key={row.name}>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    style={{ paddingTop: "8px" }}
                  >
                    {row}
                  </TableCell>
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  ₹{levelDataNew.level2[row]['todayRecharge']??0}
                  </TableCell>
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  ₹{levelDataNew.level2[row]['todayWithdrawal']??0}
                  </TableCell>
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  ₹{levelDataNew.level2[row] && levelDataNew.level2[row]['bidToday'] && levelDataNew.level2[row]['bidToday'].toFixed(2)}
                  </TableCell>
                  <TableCell align="center" style={{ paddingTop: "5px" }}>
                  {liveBalance[row] ? `₹${liveBalance[row].toFixed(2)}` : <Chip onClick={e => fetchLiveBalance(e,row)} label="Fetch Now"
                style={{ backgroundColor: "#00b8a9", color: "white" }}
              ></Chip>}
                  </TableCell>
                  
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Container style={{height: '80px'}}></Container>
    </div>
  );
};

export default PromotionRecordNew;
