import React from "react";
import ReactDom from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { reducers } from './reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';




const store = createStore(reducers, compose(applyMiddleware(thunk)));

ReactDom.render(
  <BrowserRouter>
  <Provider store={store}>
  {/* {window.location.replace(`https://game.toddapples.com`)} */}

    {/* <h5 style={{padding: '30px'}}>We are on maintanence. Please allow us some time</h5>
    <h5 style={{padding: '30px'}}>Meanwhile please visit VGA CLUB - <a href="https://vgaclub.in/login/1352642">Click here</a></h5> */}

  <App />
  </Provider>
   
  </BrowserRouter>,

  document.getElementById("root")
);
