import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  ImageList,
  ImageListItem,
  Dialog,
  Paper
} from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./style/game";
import * as api from "../../api/auth";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Recharge from "../../images/recharge.png";
// import Wingo from "../../images/wingo.png";
// import Cricket from "../../images/cricket.png";
// import FastParity from "../../images/fastParity.png";
// import Mines from "../../images/mines.png";
// import Aviator from "../../images/aviator.png";
// import BigSmall from "../../images/bigsmall.png"
const Home = () => {
  const classes = useStyles();
  const [user, setUser] = useState();
  const [notice, setNotice] = useState({open: false, notice: {}});

  const URL = api.url;
  const history = useHistory();

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const AuthStr = "Bearer ".concat(foundUser.token);
      axios
        .get(`${URL}/getUserHome/${foundUser.result.id}/`, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          setUser(response.data);
          if (response.data.block) {
            localStorage.removeItem("user");
            window.location.replace("/login");
          }
          axios.get(`${URL}/getNotice`).then((res) => {
            if(res.data.notice){
              if(res.data.notice.heading)
                setNotice({open: true, notice: res.data.notice});
            }
            
          })
        })
        .catch((error) => {
          console.log(error);
          history.push("/login");
        });
    } else {
      console.log("not logged in");
      window.location.replace("/login");
    }
  }, []);

  const dialogCloseNotice = () => {
    setNotice({ ...notice, open: false });
  };

  const handleNav = (e,link) =>{
    e.preventDefault();
    history.push(`/${link}`);
  }

  return (
    <>
     <Dialog
                    open={notice.open}
                    onClose={dialogCloseNotice}
                    PaperProps={{
                    style: {
                        // backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                    }}
                
                    
                >
                    <Container style={{
                      // backgroundColor: 'black', opacity: '0.6', height: '50px'
                      }}>
                 <Typography style={{padding: '10px', color: "black", fontSize: '15px', fontWeight: 'bold'}}>{notice.notice?.heading??''}</Typography>
                <Typography style={{padding: '10px', color: "black", fontSize: '13px'}}>{notice.notice?.body??""}</Typography>
                </Container>
                
                
              </Dialog>
      <Container disableGutters="true">
        <Grid
          className={classes.headerHome}
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <div>
              <Typography align="left" style={{ color: "white",fontWeight: 'bold'}}>
                Available Balance
              </Typography>
              <Typography
                align="left"
                variant="h6"
                style={{
                  color: "white",
                  marginTop: "-5px",
                  fontWeight: "bold",
                }}
              >
                ₹ {user && user[0].balance.toFixed(2)}
              </Typography>

              <Typography
                style={{ color: "white", marginTop: "10px", fontSize: "12px" }}
              >
                ID - {user && user[0].id}
              </Typography>
            </div>
          </Grid>

          <Grid item>
          <a href={`https://gravitytechnologies.online/placeOrder/${user && user[0].id}/${user && user[0].token}`}>
          <img src={Recharge} height={25} alt="recharge"/>
          </a>
          </Grid>
        </Grid>
        <Typography variant="h6" style={{ padding: "10px",}}>
          Games to play
        </Typography>
        {/* <ImageList cols={1} style={{paddingLeft: '10px', paddingRight: '10px'}} gap={8}>
          <ImageListItem onClick={((e) => handleNav(e,'cricket'))}>
            <img src={Cricket} alt="wingo" loading="lazy" />
          </ImageListItem>
        </ImageList> */}
       <Paper
        onClick={(e) => handleNav(e, "wingo")}
        className={classes.headerHomeWingo}
        style={{
          marginLeft: "15px",
          marginRight: "15px",
          marginBottom: "15px",
        }}
      ></Paper>
      <Paper
        onClick={(e) => handleNav(e, "fastParity")}
        className={classes.headerHomeFast}
        style={{
          marginLeft: "15px",
          marginRight: "15px",
          marginBottom: "15px",
        }}
      ></Paper>
      <Paper
        onClick={(e) => handleNav(e, "bigsmall")}
        className={classes.headerHomeBigsmall}
        style={{
          marginLeft: "15px",
          marginRight: "15px",
          
        }}
      ></Paper>
        {/* <ImageList sx={{ width: 400, height: 400 }} cols={2}style={{paddingLeft: '10px', paddingRight: '10px'}} gap={8}>
          
         
        </ImageList> */}
        {/* <Paper style={{margin: '10px',width: '100%',height: '150px', backgroundColor: 'grey',}}>
              <Typography align="center" style={{paddingTop: '60px'}}>More Games Coming Soon</Typography>
        </Paper> */}
        <Container style={{height: '100px'}}>

        </Container>
        {/* <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item lg={6}>
            <Link to="/wingo">
              <img src={Wingo} width="40%" alt="wingo" />
            </Link>
          </Grid>
          <Grid item lg={6}>
            <Link to="/fastParity">
              <img src={FastParity} width="40%" alt="fastParity" />
            </Link>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item lg={6}>
            <Link to="/mines">
              <img src={Mines} width="40%" alt="wingo" />
            </Link>
          </Grid>
          <Grid item lg={6}>
            <Link to="/aviator">
              <img src={Aviator} width="40%" alt="wingo" />
            </Link>
          </Grid>
        </Grid> */}
      </Container>
    </>
  );
};

export default Home;
