import {React,useEffect,useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { Link } from "react-router-dom";
import HomeIcon from "../images/homeWingo.png";
import Support from "../images/support.png";
import Support1 from "../images/support1.png";
import Home from "../images/home.png";
import Cricket from "../images/cricket-2.png"
import MyIconColor from "../images/myColor.png";
import MyIcon from "../images/my.png";



const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
});

export default function NavBar() {
  const classes = useStyles();
  const pathname = window.location.pathname; // in case user visits the path directly. The BottomNavBar is able to follow suit.
    const [value, setValue] = useState(pathname);
    const [isAuth, setAuth] = useState(false);
    const handleChange = (event, newValue) => {
      const loggedInUser =  localStorage.getItem("user");
      if (loggedInUser) {
         setAuth(true)
      
       
      }else{
        setAuth(false)
  
      }
      setValue(newValue);
    };

    const [time, setTime] = useState(new Date());

  useEffect(() => {
    
    const interval = setInterval(() => {
      
      setTime(new Date());
const loggedInUser =  localStorage.getItem("user");
      if (loggedInUser) {
         setAuth(true)
      
       
      }else{
        setAuth(false)
  
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

    


    return (
      
      <BottomNavigation
      value={value} onChange={handleChange} showLabels={true}
        className={classes.root}
      >
        <BottomNavigationAction label="Home" value="/" icon={<img src={value === '/' ? Home : HomeIcon} height={20} alt="HomeIcon"/>} component={Link} to='/' style={{color: value === '/' ?'#00b8a9':'grey'}}/>
        
        {/* <BottomNavigationAction label="Cricket" value={isAuth ?"/cricket" : "/login"} icon={<img src={value === "/cricket" ? Cricket : Cricket} height={20} alt="myicon"/>} component={Link} to={isAuth ?"/cricket" : '/login'} style={{color: value === '/cricket' ?'#00b8a9':'grey'}}/> */}

        <BottomNavigationAction label="My" value={isAuth ?"/profile" : "/login"} icon={<img src={value === "/profile" ? MyIconColor : MyIcon} height={20} alt="myicon"/>} component={Link} to={isAuth ?"/profile" : '/login'} style={{color: value === '/profile' ?'#00b8a9':'grey'}}/>
        <BottomNavigationAction label="Help" value={"/help"} icon={<img src={value === "/help" ? Support1 : Support} height={20} alt="myicon"/>} component={Link} to={'/help'} style={{color: value === '/help' ?'#00b8a9':'grey'}}/>

      </BottomNavigation>
      
    );
}
