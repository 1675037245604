import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  TableContainer,
  Table,
  TableRow,
  Container,
  TableCell,
  TableHead,
  TableBody,
  Dialog,
  CircularProgress,
  Button,
  DialogContent,
  Box,
  CardContent,
  Card,
  Avatar,
} from "@material-ui/core/";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import * as api from "../../../api/auth";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const AgentEarning = () => {
  const dispatch = useDispatch();
  const [isAuth, setAuth] = useState(false);
  const history = useHistory();
  const URL = api.url;
  const [earning, setEarning] = useState(
    {
      dailyTask: { today: 0, total: 0 },
      refer: { today: 0, total: 0 },
      level0: { today: 0, total: 0 },
      level1: { today: 0, total: 0 },
      level2: { today: 0, total: 0 },
    }
  );

  const [openDialog, setDialog] = React.useState({ open: false, body: "" });
  const [loader, setLoader] = React.useState(true);
  const [ruleDialog, setRule] = React.useState(false);
  const ruleClose = () => {
    setRule(false);
  };
  const openRule = () => {
    setRule(true);
  };

  const dialogClose = () => {
    setDialog({ ...openDialog, open: false });
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setAuth(foundUser);
      const AuthStr = "Bearer ".concat(foundUser.token);
      axios
        .get(`${URL}/getAgentEarnings/${foundUser.result.id}`, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          setEarning(response.data);
          setLoader(false);
        })
        .catch((error) => {});
    } else {
      history.push("/login");
    }
  }, []);


  

  return (
    <div
      style={{
        
        minHeight: "100vh",
        paddingBottom: "100px",
      }}
    >
      <Dialog
        open={ruleDialog}
        onClose={ruleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography
            align="center"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              paddingBottom: "20px",
            }}
          >
            Agent Earnings
          </Typography>

          <Typography
            align="left"
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
          >
            Recharge Bonus:
          </Typography>
          <Typography
            align="left"
            style={{ fontSize: "12px", paddingBottom: "20px" }}
          >
            When your level 1 member recharge you get 9 % of recharge amount credited automatically to your wallet balance.
          </Typography>
          <Typography
            align="left"
            style={{ fontSize: "12px", paddingBottom: "20px" }}
          >
            When your level 2 member recharge you get 4 % of recharge amount credited automatically to your wallet balance.
          </Typography>
          <Typography
            align="left"
            style={{ fontSize: "12px", paddingBottom: "20px" }}
          >
            When your level 3 member recharge you get 3 % of recharge amount credited automatically to your wallet balance.
          </Typography>
          
          

          <Typography
            align="left"
            style={{ fontSize: "12px", paddingBottom: "20px" }}
          >
            {" "}
            Eg - If level 1 member recharge amount is 10000 ruppes you will get 900 wallet bonus
          </Typography>
         

          <Typography
            align="left"
            style={{ fontSize: "12px", paddingBottom: "20px" }}
          >
            जब आपका लेवल 1 सदस्य रिचार्ज करता है तो रिचार्ज राशि का 9% स्वचालित रूप से आपके वॉलेट बैलेंस में जमा हो जाता है।
          </Typography>
          <Typography
            align="left"
            style={{ fontSize: "12px", paddingBottom: "20px" }}
          >
            जब आपका लेवल 2 सदस्य रिचार्ज करता है तो रिचार्ज राशि का 4% स्वचालित रूप से आपके वॉलेट बैलेंस में जमा हो जाता है।
          </Typography>
          <Typography
            align="left"
            style={{ fontSize: "12px", paddingBottom: "20px" }}
          >
            जब आपका लेवल 3 सदस्य रिचार्ज करता है तो रिचार्ज राशि का 3% स्वचालित रूप से आपके वॉलेट बैलेंस में जमा हो जाता है।
          </Typography>
         
          <Typography
            align="left"
            style={{ fontSize: "14px", paddingBottom: "20px" }}
          >
            उदाहरण - लेवल 1 के सदस्य की रिचार्ज राशि 10000 रुपये है तो आपको 900 वॉलेट बोनस मिलेगा
          </Typography>

         

         
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={ruleClose} color="primary" >
            OK
          </Button>
         
        </DialogActions> */}
      </Dialog>
      <Dialog
        open={loader}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Container
          align="center"
          style={{
            backgroundColor: "black",
            opacity: "0.6",
            height: "100px",
            paddingTop: "10px",
          }}
        >
          <CircularProgress style={{ color: "black" }} />
          <Typography style={{ paddingTop: "10px", color: "black" }}>
            Please Wait!
          </Typography>
        </Container>
      </Dialog>
      <Dialog
        open={openDialog.open}
        onClose={dialogClose}
        PaperProps={{
          style: {
            // backgroundColor: 'transparent',
            boxShadow: "none",
          },
        }}
      >
        <Container
          style={
            {
              // backgroundColor: 'black', opacity: '0.6', height: '50px'
            }
          }
        >
          <Typography style={{ padding: "10px", color: "black" }}>
            {openDialog.body}
          </Typography>
        </Container>
      </Dialog>

      <Grid
        container
        direction="row"
        justify=""
        alignItems="center"
        style={{
          paddingLeft: "20px",
          paddingTop: "15px",
          paddingBottom: "15px",
          paddingRight: "20px",
         
        }}
      >
        <Grid item xs={4}>
          <ArrowBackIosIcon
            style={{ fontSize: "20px", color: "black" }}
            onClick={history.goBack}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography align="center" style={{ color: "black" }}>
            Agent Earning
          </Typography>
        </Grid>
      </Grid>

      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{ fontWeight: "bold", color: "black" }}
              >
                Agent Inviting and other bonus
              </TableCell>

              <TableCell align="start" style={{ fontWeight: "bold", color: "black" }}>
                Amount Credited
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                style={{
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                Today Daily Task Bonus
                <Typography
                  style={{
                    fontSize: "12px",

                    color: "black",
                  }}
                >
                  Total Daily Task Bonus
                </Typography>
              </TableCell>
              <TableCell
                align="start"
                style={{
                  backgroundColor: "white",
                  fontSize: "14px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                ₹ {earning?.dailyTask?.today??0}
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "green",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  ₹ {earning.dailyTask.total}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                style={{
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                Today Referral Bonus
                <Typography
                  style={{
                    fontSize: "12px",

                    color: "black",
                  }}
                >
                  Total Referral Bonus
                </Typography>
              </TableCell>
              <TableCell
                align="start"
                style={{
                  fontSize: "14px",

                  fontWeight: "bold",
                  backgroundColor: "white",

                  color: "black",
                }}
              >
                ₹ {earning.refer.today}
                <Typography
                  style={{
                    fontSize: "14px",

                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  ₹ {earning.refer.total}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                style={{
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                Today Level 1 Recharge Bonus
                <Typography
                  style={{
                    fontSize: "12px",

                    color: "black",
                  }}
                >
                  Total Level 1 Recharge Bonus
                </Typography>
              </TableCell>
              <TableCell
                align="start"
                style={{
                  fontSize: "14px",

                  fontWeight: "bold",
                  backgroundColor: "white",

                  color: "black",
                }}
              >
                ₹ {earning.level0.today}
                <Typography
                  style={{
                    fontSize: "14px",

                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  ₹ {earning.level0.total}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                style={{
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                Today Level 2 Recharge Bonus
                <Typography
                  style={{
                    fontSize: "12px",

                    color: "black",
                  }}
                >
                  Total Level 2 Recharge Bonus
                </Typography>
              </TableCell>
              <TableCell
                align="start"
                style={{
                  fontSize: "14px",

                  fontWeight: "bold",
                  backgroundColor: "white",

                  color: "black",
                }}
              >
                ₹ {earning.level1.today}
                <Typography
                  style={{
                    fontSize: "14px",

                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  ₹ {earning.level1.total}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                style={{
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                Today Level 3 Recharge Bonus
                <Typography
                  style={{
                    fontSize: "12px",

                    color: "black",
                  }}
                >
                  Total Level 3 Recharge Bonus
                </Typography>
              </TableCell>
              <TableCell
                align="start"
                style={{
                  fontSize: "14px",

                  fontWeight: "bold",
                  backgroundColor: "white",

                  color: "black",
                }}
              >
                ₹ {earning.level2.today}
                <Typography
                  style={{
                    fontSize: "14px",

                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  ₹ {earning.level2.total}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Container style={{ paddingTop: "20px" }}>
        <Container align="center">
          <Button
            variant="outlined"
            align="center"
            style={{ color: "green" }}
            onClick={openRule}
          >
            How it works?
          </Button>
        </Container>
      </Container>
    </div>
  );
};

export default AgentEarning;
