import React from 'react';
import {Route, Switch, } from "react-router-dom";
import AuthForm from "./components/auth/authForm";
import Forget from "./components/auth/forget";
import Home from "./components/home/home";
import FullBidHistory from "./components/home/game/fullBid";
import FullRecord from "./components/home/game/fullRecord";
import Profile from "./components/profile/profile";
import NavBar from "./components/nav";
import About from "./components/profile/about/about";
import PrivacyPolicy from "./components/profile/about/privacy";
import Risk from "./components/profile/about/risk";
import AppStatement from "./components/profile/about/appStatement";
import MyTask from "./components/profile/about/mytask";
import Refund from "./components/profile/about/refund";
import AboutUs from "./components/profile/about/aboutus";
import MyRedEnvelop from "./components/profile/wallet/redEnvelop";
import Envelope from "./components/profile/envelop";
import AccountSecurity from "./components/profile/accSeq/accSeq";
import ModifyName from "./components/profile/accSeq/name";
import ModifyPassword from "./components/profile/accSeq/pass";
import ModifyPayment from "./components/profile/accSeq/payment";
import Financial from "./components/profile/financial";
import MyPromotion from "./components/profile/promotion";
import Invite from "./components/profile/invite";
import PromotionRecord from "./components/profile/promRecord";
import ApplyRecord from "./components/profile/applyRec";
import MyBank from "./components/profile/bank";
import MyAddress from "./components/profile/address";
import ApplyPromotion from "./components/profile/appPromotion";
import Terms from "./components/profile/terms";
import Contact from './components/profile/about/contact';
import MyWallet from './components/profile/wallet/mywallet';
import PreOrder from './components/profile/wallet/recharge';
import Withdrawal from './components/profile/wallet/withdrawal';
import WithdrawalHistory from './components/profile/wallet/withdrawalHistory';
import RedEnvelopHistory from './components/profile/wallet/redEnvelopHistory';
import MinesGamePage from './components/Mines/game.jsx';
import GamePage from './components/home/game/game';
import FullMinesBidHistory from './components/Mines/fullMinesBid';
import FastParityGamePage from './components/fastParity/fastParity_game';
import FastParityFullRecord from './components/fastParity/fastParity_fullRecord';
import FastParityFullBidHistory from './components/fastParity/fastParity_fullBid';
import PromotionRecordNew from './components/profile/promRecordNew';
import AviatorGamePage from './components/aviator/aviator';
import AviatorFullRecord from './components/aviator/fullRecord';
import AviatorFullBidHistory from './components/aviator/fullBid';
import Help from './components/profile/support.js';
import MySalary from './components/profile/wallet/mySalary.js';
import CricketGamePage from './components/cricket/cricket.jsx';
import { MatchPage } from './components/cricket/match.jsx';
import CricketFullBidHistory from './components/cricket/fullBid.js';
import BigSmallFullBidHistory from './components/bigSmall/BigSmall_fullBid.js';
import BigSmallFullRecord from './components/bigSmall/BigSmall_fullRecord.js';
import BigSmallGamePage from './components/bigSmall/BigSmall_game.jsx';
import MyDailyTask from './components/profile/wallet/dailyTask.js';
import RechargeHistory from './components/profile/wallet/history.js';
import AgentEarning from './components/profile/wallet/agentEarning.jsx';


const App = () => {


  return (
  

    
    <>
     <Switch>
     <Route exact path="/">
      <Home/>
    </Route>
    <Route exact path="/about-us">
      <About/>
    </Route>
    <Route exact path="/shipping-policy">
     
    </Route>
    <Route exact path="/resetPassword">
      <Forget/>
    </Route>
    <Route exact path="/myTask">
      <MyTask/>
    </Route>
    {/* <Route exact path="/mySalary">
      <MySalary/>
    </Route> */}
    <Route exact path="/agent-earning">
      <AgentEarning/>
    </Route>
    <Route exact path="/dailyTask">
      <MyDailyTask/>
    </Route>
    <Route exact path="/contact">
      <Contact/>
    </Route>
    <Route exact path="/applyRecord">
      <ApplyRecord/>
    </Route>
    <Route exact path="/invite">
      <Invite/>
    </Route>
    <Route exact path="/redenvelope">
      <MyRedEnvelop/>
    </Route>
    <Route exact path="/redEnvelopeHistory">
      <RedEnvelopHistory/>
    </Route>
    <Route exact path="/financial">
      <Financial/>
    </Route>
    <Route exact path="/bank">
      <MyBank/>
    </Route>
    <Route exact path="/accountSecurity">
      <AccountSecurity/>
    </Route>
    <Route exact path="/accountSecurity/name">
      <ModifyName/>
    </Route>
    <Route exact path="/accountSecurity/password">
      <ModifyPassword/>
    </Route>
    <Route exact path="/accountSecurity/payment">
      <ModifyPayment/>
    </Route>
    <Route exact path="/about-us/about">
      <AboutUs/>
    </Route>
    <Route exact path="/about-us/privacy">
      <PrivacyPolicy/>
    </Route>
    <Route exact path="/terms">
      <Terms/>
    </Route>
    <Route exact path="/refund">
      <Refund/>
    </Route>
    <Route exact path="/cancellation-policy">
      
    </Route>
    <Route exact path="/about-us/RDA">
      <Risk/>
    </Route>
    <Route exact path="/mypromotion">
      <MyPromotion/>
    </Route>
    <Route exact path="/mypromotion/apply">
      <ApplyPromotion/>
    </Route>
    <Route exact path="/about-us/app-statement">
      <AppStatement/>
    </Route>
    <Route exact path="/address">
      <MyAddress/>
    </Route>
    <Route exact path="/bidHistory/:game">
      <FullBidHistory/>
    </Route>
    <Route exact path="/fastParity/bidHistory">
      <FastParityFullBidHistory/>
    </Route>
    <Route exact path="/bigsmall/bidHistory">
      <BigSmallFullBidHistory/>
    </Route>
    <Route exact path="/mines/playHistory">
      <FullMinesBidHistory/>
    </Route>
    <Route exact path="/record/:game">
      <FullRecord/>
    </Route>
    <Route exact path="/fastParity/record">
      <FastParityFullRecord/>
    </Route>
    <Route exact path="/bigsmall/record">
      <BigSmallFullRecord/>
    </Route>
    <Route exact path="/aviator/history">
      <AviatorFullBidHistory/>
    </Route>
    {/* <Route exact path="/cricket/history/:id/:team1/:team2">
      <CricketFullBidHistory/>
    </Route>
    <Route exact path="/cricket/history">
      <CricketFullBidHistory/>
    </Route> */}
    <Route exact path="/aviator/record">
      <AviatorFullRecord/>
    </Route>
    <Route exact path="/promotionRecord/:id">
      <PromotionRecord/>
    </Route>
    <Route exact path="/promotionRecordNew/:id">
      <PromotionRecordNew/>
    </Route>
    <Route exact path="/wallet">
      <MyWallet/>
    </Route>
    <Route exact path="/withdrawal">
      <Withdrawal/>
    </Route>
    <Route exact path="/preOrder">
      <PreOrder/>
    </Route>
    {/* <Route exact path="/testRecharge">
      <TestRecharge/>
    </Route>  */}
     <Route exact path="/rechargeHistory">
      <RechargeHistory/>
    </Route>
    <Route exact path="/withdrawalHistory">
      <WithdrawalHistory/>
    </Route>
    <Route path="/getRedEnvelop/:id">
        <Envelope />
    </Route>
    
    
    </Switch>
      <Switch>
      <Route exact path="/aviator">
      <AviatorGamePage />
      </Route>
      <Route exact path="/mines">
      <MinesGamePage />
      </Route>
      {/* <Route exact path="/cricket">
      <CricketGamePage />
      </Route> */}
      {/* <Route exact path="/matchPage/:id/:team1/:team2/:match/:date">
      <MatchPage />
      </Route> */}
      
      <Route exact path="/wingo">
      <GamePage />
      </Route>

      <Route exact path="/fastParity">
      <FastParityGamePage />
      </Route>

      <Route exact path="/bigsmall">
      <BigSmallGamePage />
      </Route>
      
      <Route path="/login/:id">
        <AuthForm />
      </Route>
      <Route path="/login">
        <AuthForm />
      </Route>
      <Route path="/profile">
        <Profile />
      </Route>
      <Route path="/help">
        <Help />
      </Route>
    </Switch>
    <NavBar/>    
           

    
    </>
  
  );
};

export default App;
